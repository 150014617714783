import React from 'react'
import arrow from '../images/arrow.png'

class Collapsible extends React.Component {

  render() {
    const { sectionName, name, text, image, images } = this.props

    return (
      <div class="collapsible-menu">
        <span className="arrow"><img src={arrow} alt="" /></span>
        <input type="checkbox" id={sectionName} />
        <label for={sectionName}><h3>{name}</h3></label>
        <div class="menu-content">
          <ul>
            { text && <div><p>{text}</p></div>}
            { image && 
              images.map(image => <div><img src={image} alt={image} /></div>)
            }
          </ul>
        </div>
        <style jsx>{`

.menu-content {
            max-height: 0;
            overflow: hidden;
            font-family: 'Oswald', sans-serif; 
            padding: 0 0 0 50px;
        }

        .arrow img {
          width: 21px;
          position: relative;
          left: 23px;
          top: 3px;
        }

        /* Toggle Effect */
        input:checked ~ label {
            background-image: url(close.png);
        }

        input:checked ~ .menu-content {
            max-height: 100%;
        }
        checkbox input[type=checkbox]:checked + label {
          left: 84px;
          background: #26ca28;
        }

        img {
          width: 100%;
        }

        @media (max-width: 600px) {
          .arrow img {
            width: 19px;
            position: relative;
            left: 21px;
            top: 4px;
          }
        }
          
      `}</style>
      </div>
    )
  }
}

export default Collapsible