import React from 'react'
import PropTypes from 'prop-types'
import Iframe from 'react-iframe'

import work3 from '../images/work3.jpg'
import mummy from '../images/mummy.jpg'
import Collapsible from './collapsible'
import bottlePlant from '../images/bottle-plant.jpg'
import decoratedPlants from '../images/decorated-plants.jpg'
import happyChildren from '../images/happy-children.jpg'
import teacherStudent from '../images/teacher-student.jpg'
import verticalGarden1 from '../images/vertical1.jpg'
import verticalGarden2 from '../images/vertical-garden2.jpg'
import verticalGarden3 from '../images/vertical-garden3.jpg'
import garden1 from '../images/garden1.jpg'
import garden2 from '../images/garden2.jpg'
import livingSpaces from '../images/livingSpaces.jpg'
import livingSpaces2 from '../images/livingSpaces2.jpg'
import livingSpaces3 from '../images/livingSpaces3.jpg'
import showroom2 from '../images/showroom2.jpg'
import showroom3 from '../images/showroom3.jpg'
import cafe1 from '../images/cafe1.jpg'
import cafe2 from '../images/cafe2.jpg'
import cafe3 from '../images/cafe3.jpg'
import cafe4 from '../images/cafe4.jpg'
import pond1 from '../images/pond1.jpg'
import mural from '../images/mural.jpg'
import gap from '../images/gap.jpg'
import gap1 from '../images/gap1.jpg'
import gap2 from '../images/gap2.jpg'
import gap3 from '../images/gap3.jpg'
import birdHouse1 from '../images/birdHouse1.jpg'
import birdHouse2 from '../images/birdHouse2.jpg'
import birdFeeder from '../images/birdFeeder.jpg'
class Main extends React.Component {
  state = {
    expanded: false
  }
  handleClick = (params) => {
    this.setState = {
      expanded: !this.state.expanded
    }
  }
  render() {
    let close = <div className="close" onClick={() => {this.props.onCloseArticle()}}></div>
    
    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>

        <article id="blog" className={`${this.props.article === 'blog' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">blog</h2>
          {/* <span className="image main"><img src={} alt="" /></span> */}
          <p>At Kyaari we believe in sharing our knowledge about plants. Check out some of my favourite articles about plants</p>
          <p><a href="https://medium.com/@prakhar22july/things-to-know-when-you-buy-plants-a153beac4920?fbclid=IwAR1xHV1HzErO6odXBxcQHBOw3zkVoyeYVx0lrBhFsywAQEAiHvQN8Ndv_0Y">Things to know when you buy plants!</a></p>
          <p><a href="https://medium.com/@prakhar22july/caring-for-plants-in-summers-humid-heat-fcc2d5d184c?fbclid=IwAR3apWW-yj8NLcGu7R43QB1BvoZ4V5WSxYikbMA2hXca1LBvHOmq6LSnPXU">Caring for plants in summer's humid heat</a></p>
          {close}
        </article>

        <article id="work" className={`${this.props.article === 'work' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Work</h2>
          <p>These are some of our proud achievements so far. For more information visit our instagram handle <a href="https://www.instagram.com/kyaari_com/">kyaari_com</a>.</p>
          <Collapsible 
            sectionName="garden"
            name="garden"
            image={true}
            images={[work3, garden1, garden2]}
            text={"A garden isn't meant to be useful. It's for joy"}
          />
          <Collapsible 
            sectionName="livingspacesbalconies"
            name="Home/balconies"
            image={true}
            images={[livingSpaces, livingSpaces2, livingSpaces3]}
            text={'Coming home should be one of the most beautiful things. At Kyaari, we try our best to provide you with a space which would be perfect for relaxing and destressing.'}
          />
          <Collapsible 
            sectionName="showrooms"
            name="showrooms"
            image={true}
            images={[ showroom2, showroom3]}
          />
          <Collapsible
            sectionName="cafesandrestaurant"
            name="cafe and reataurant"
            image={true}
            images={[cafe1, cafe2, cafe3, cafe4]}
          />          
          <Collapsible
            sectionName="pond"
            name="pond"
            image={true}
            images={[pond1]}
          />
          <Collapsible
            sectionName="fountainsandmurals"
            name="fountains and murals"
            image={true}
            images={[mural]}/>
          <Collapsible 
            sectionName="verticalgardens"
            name="vertical gardens"
            image={true}
            images={[verticalGarden1, verticalGarden2, verticalGarden3]}
            text={'The garden suggests that there might be a place where we can meet nature halfway'}
          />
          <Collapsible 
            sectionName="helping" 
            name="helping our city" 
            image={true} 
            images={[bottlePlant, decoratedPlants, happyChildren, teacherStudent]} 
            text={'At Kyaari we believe in helping our environment by planting more plants. Here is our effort towards having a much more cleaner and greener Lucknow.'}
          />
          {close}
        </article>

        <article id="products" className={`${this.props.article === 'products' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Products</h2>
          <p>Here are some of our best sellers, you can also buy these at our store in lekhraj market, lucknow. Come down to explore our complete range of products</p>
          <Collapsible
            sectionName="gift" 
            name="Gift a plant" 
            text={"At kyaari we encourage you to gift plants. We usually provide small return gifts to our guests at social gatherings and meetings,why not gift a well decorated planter with a cute little living plant.We custom design pots and plants according to the need of the customer and add all necessary text and messages,We also provide carry bags/cases for the plant."}
            images={[gap, gap1, gap2, gap3]}
            image={true}
            />
          <Collapsible 
            sectionName="birdHouse"
            name="bird House" 
            text="Our bird houses are made to perfection for sparrows.we have consulted and carefully incorporated all the details provided to us by the sparrow society of india"
            images={[birdHouse1, birdHouse2]}
            image={true}/>
          <Collapsible
            sectionName="birdFeeder"
            name="bird feeder" text="Kyaari bird feeders are builf from the best quality material.the design has been approved by the sparrow society of india and it has also been tested for all kinds of grain,all kind of birds and all kinds of weather."
            images={[birdFeeder]}
            image={true}
          />
          <Collapsible sectionName="hummingbirdFeeder" name="humming bird feeder" text="Hummingbirds mean a lot to us at kyaari,we believe they are the best possible creatures on the earth,we have desiged a feeder to thank the little bird.fill it up with sugar syrup and treat the hardworking hummingbird." images={[]}/>
          <Collapsible sectionName="fungicide" name="fungicide" text="Kyaari fungicide is very helpful for plants with root rot and stem rot,just make a paste or directly apply at the affected area and see the difference" images={[]}/>
          <Collapsible sectionName="rootingHarmone" name="Rooting Harmone" text="Are you tired of trying to root your plant cuttings but they just refuse to reviv? ,we at kyaari have formulated a perfect rooting harmone for all garden plants.just dip the tip in the harmone and plant it in porous soil,the results will be amazing." images={[]}/>
          <Collapsible sectionName="organicLiquidFertilizer" name="Organic fertilizer" text="Our special organic liquid fertilizer is the best treat you can provide to your plant.just add 2 tablespoon in a gallon of water and water near the roots of the plant for a healthy,greener plant." images={[]}/>
          <Collapsible sectionName="gardenNutrients" name="garden nutrients" text="We have a huge range of all the neutrients required to make a garden  super healthy and productive. we are currantly producing organic manure,cow dung manure,leaf manure,vermicompost,urea,potash,DAP,NPK,zinc,liquid fertilizer,neem khali,mustard khali,perlite,bone meal,rose mix,adenium mix,cactus mix and other neutrients for your garden." images={[]}/>
          <Collapsible sectionName="kyaariCards" name="Kyaari Cards" text="Kyaari believs in recycing and reusing whatever can be used again,we dehydrate our own flowers from our garden and produce greeting cards which are a great gift and an even better way to thank nature." images={[]}/>
          <Collapsible sectionName="kyaariJewellery" name="Kyaari Jewellery" text="Our love for recycling and reusing led us to creating these pieces of jewellery,we search the jungles for amazing seeds and flowers which can be preserved as jewellery,most of the seeds we use are the one's which refuse to germinate." images={[]}/>

          {close}
        </article>

        <article id="about" className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">About</h2>
          <span className="image main"><img src={mummy} alt="" /></span>
          <p>Kyaari is an initiative to help India become carbon negative, pollution is rampant in major parts of the country which is adversely affecting our health. We at Kyaari believe that planting more trees is the best way to save our mother earth. Kyaari is working hard day and night to grow special plant species which are easy to grow and care for. We believe with more beautiful and artistically pleasing plants arrangements we can attract more people towards adopting plants for their home and offices. With our modern plantation techniques like vertical gardens, inverted planters and succulent arrangements we are trying to provide a new artistic touch to the current gardening habits of our users. Kyaari wishes to some day soon see India too as a carbon negative, healthy and happy country.</p>
          {close}
        </article>



        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Contact</h2>
          
              <div className="email-container"><span><label htmlFor="email"><b>Write to us:</b> </label></span><span className="email"><a href="mailto:prakhar22july@gmail.com">prakhar22july@gmail.com</a></span></div>
              <label><b>Call us:</b> +91 8896353701</label>
              <label><b className="visit">Visit us:</b> <br></br>Kyaari, the art nursery, <br></br>Shop-UGF-4, Chandrakala Trade Center, <br></br>Sector-B, Indranagar, <br></br>Lucknow , <br></br>UttarPradesh, <br></br>India</label>
          <ul className="icons">
          <li><a href="https://www.facebook.com/kyaariwork/" className="icon fa-facebook"><span className="label">Facebook</span></a></li>
          <li><a href="https://www.instagram.com/kyaari_com/?hl=en" className="icon fa-instagram"><span className="label">Instagram</span></a></li>
          </ul>
          <ul>
          <div className="map-container">
          <Iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1779.3623680270148!2d80.98142805797231!3d26.880485288187558!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bfd32ed5b7805%3A0x73d69d55b87c73fa!2sKyaari%20(the%20art%20nursery)!5e0!3m2!1sen!2suk!4v1567857810745!5m2!1sen!2suk"
            height= '600px'
            width='97%'
            marginRight="10px"
          />
          </div>
          </ul>
          {close}
        </article>
        <style jsx>{`
          .map-container {
            overflow: hidden;
            // Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 0.5625)
            padding-top: 56.25%;
            position: relative;
          }
         .email {
          position: relative;
          bottom: 40px;
          left: 117px;
         }
         .email-container {
          position: relative;
          top: 20px;
         }
         @media (max-width: 600px) {
          .email {
            bottom: 37px;
            left: 112px;
          }
        }
      `}</style>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main