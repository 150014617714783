import React from 'react'
import PropTypes from 'prop-types'
import kyaariLogoText from '../images/kyaariTextLogoRed.svg'
import plant from '../images/kyaari.png'
const Header = (props) => (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
        <div className="block"></div>
        <img className="plant" src={plant}/>
        <div className="content">
            <div className="inner">
            <img className="logoText" src={kyaariLogoText} alt="kyaariLogoText"/>
                <div className="quote">"To plant a garden is to believe in tomorrow."<br /></div>
            </div>
        </div>
        <nav>
            <ul>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('about')}}>About</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('work')}}>Work</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('products')}}>Products</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('blog')}}>Blog</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('contact')}}>Contact</a></li>
            </ul>
        </nav>
        <style jsx global>{`
            .inner {
                align-text: center;
            }
            .logoText {
                height: 75px;
                width: 325;
            }
            .plant {
                max-width: 86px;
                width: 86px;
                border-radius: 50%;
            }
            .block {
                background-color: white;
                padding: px;
                width: 80px;
                height: 78px;
                position: absolute;
                top: 114px;
                border-radius: 50%;
            }
            .quote {
                padding-top: 20px;
            }
            @media only screen and (min-width: 600px) {
                .block {
                    top: 138px;
                }
              }
            }
            
        `}</style>
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
